import SemenceLocalisationTransformer from '@/assets/js/dexie/transformers/SemenceLocalisationTransformer'
import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'
import _orderBy from 'lodash/orderBy'

export default {

	methods: {
		async getLocalisationSemence() {
			const localisations = await this.$storage.db.t('semence_localisation')
			.then(table => {
				return table.where({
					semencelocalisation_parent: 0
				}).sortBy('semencelocalisation_order')
			})
			.then(async array => {
				return await SemenceLocalisationTransformer.process(array)
			})
			.then(arr => {
				return this.flattenLocalisations(_orderBy(arr, ['semencelocalisation_order'], ['asc']))
			})

			for(let i in localisations) {
				localisations[i].semencelocalisation_fullname = this.fullName(localisations[i], '', localisations)
			}

			return localisations
		},

		async getLocalisationSemenceFullName() {
			const localisations = await this.$storage.db.t('semence_localisation')
			.then(table => {
				return table.orderBy('semencelocalisation_order')
			})
			.then(async array => { 
				return await SemenceLocalisationTransformer.process(array)
			})

			for(let i in localisations) {
				localisations[i].full_name = this.fullName(localisations[i], '', localisations)
				localisations[i].semencelocalisation_prestockage = this.prestockageParent(localisations[i], false, localisations)
			}

			return localisations
		},

		fullName(localisation, name, localisations) {
			if(localisation.semencelocalisation_parent == 0) {
				if(name) {
					return localisation.semencelocalisation_libelle + " / " + name
				}
				return localisation.semencelocalisation_libelle
			}
			const parent = localisations.find(loc => loc.semencelocalisation_id == localisation.semencelocalisation_parent)
			if(name) {
				return this.fullName(parent, localisation.semencelocalisation_libelle, localisations) + " / " + name
			}
			return this.fullName(parent, localisation.semencelocalisation_libelle, localisations)
		},

		prestockageParent(localisation, prestockage, localisations) {
			if(localisation.semencelocalisation_parent == 0) {
				return localisation.semencelocalisation_prestockage
			}
			const parent = localisations.find(loc => loc.semencelocalisation_id == localisation.semencelocalisation_parent)
			if(name) {
				return this.prestockageParent(parent, localisation.semencelocalisation_prestockage, localisations) || prestockage
			}
			return this.prestockageParent(parent, localisation.semencelocalisation_prestockage, localisations)
		},

		getLocalisationSemenceById(loc_id) {
			return this.$storage.db.t('semence_localisation')
			.then(table => {
				return table.where({
					semencelocalisation_id: parseInt(loc_id),
				})
			})
			.then(col => {
				return col.transform(new SemenceLocalisationTransformer())
			})
		},

		createLocSemence(parent, libelle, prestockage, nb_floors) {
			const loc_id = Common.getNegativeId()

			return this.$storage.db.t('semence_localisation')
                .then(table => {
                    return table.add({
                    	semencelocalisation_id: loc_id,
                        semencelocalisation_parent: parent,
                        semencelocalisation_libelle: libelle,
                        semencelocalisation_licencekey: Constants.USER_LICENCE_KEY,
                        semencelocalisation_valide: 1,
                        semencelocalisation_order: 999,
                        semencelocalisation_prestockage: prestockage,
                        semencelocalisation_nb_floors: nb_floors
                    })
                })
                .then(col => {
					return loc_id
				})
		},

		updateLocSemence(loc_id, parent, libelle, prestockage, nb_floors) {
			return this.$storage.db.t('semence_localisation')
                .then(table => {
                    return table.update(parseInt(loc_id), {
                        semencelocalisation_parent: parent,
                        semencelocalisation_libelle: libelle,
                        semencelocalisation_prestockage: prestockage,
                        semencelocalisation_nb_floors: nb_floors
                    })
                })
		},

		async deleteLocSemence(loc_id) {
			await this.$storage.db.t('semence_localisation')
			    .then(table => {
			    	return table.where('semencelocalisation_id').equals(parseInt(loc_id)).invalid()
			    })

			let children = await this.$storage.db.t('semence_localisation')
			.then(table => {
				return table.where({
					semencelocalisation_parent: parseInt(loc_id),
				})
			})
			.then(col => {
				return col.transform(new SemenceLocalisationTransformer('withoutChildren'))
			})

			if(children.length === 0) return true

			children.forEach(async child => {
				await this.deleteLocSemence(child.semencelocalisation_id)
			})
		},

		flattenLocalisations(localisations, indent=0) {
			let flatten = []
	
			localisations.forEach(loc => {
				loc.indent = indent
				flatten.push(loc)
				flatten = flatten.concat(
					this.flattenLocalisations(_orderBy(loc.children, ['semencelocalisation_order'], ['asc']), indent + 1)
				)
			})
			return flatten
		},

		async saveLocalisationSemenceOrder(localisations) {
			await Common.asyncForEach(localisations, async (loc) => {
				await this.$storage.db.t('semence_localisation')
                .then(table => {
                    return table.update(parseInt(loc.semencelocalisation_id), {
                        semencelocalisation_order: loc.semencelocalisation_order,
                    })
                })
			})
		},

		loadAllStockDistant() {
			let url = Constants.STOCK_DISTANT_SEMENCE_URL + "?licence_key=" + Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("StockSemenceMixin::loadAllStockDistant", url)
				.catch(e => {
					console.error("StockSemenceMixin::loadAllStockDistant => ", e)
					return null
				})
				.then(res => res.retour)
		},

		loadAllStockLocal() {
			let url = Constants.STOCK_LOCAL_SEMENCE_URL + "?licence_key=" + Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("StockSemenceMixin::loadAllStockLocal", url)
				.catch(e => {
					console.error("StockSemenceMixin::loadAllStockLocal => ", e)
					return null
				})
				.then(res => res.retour)
		},

		async exportAllStockLocal() {
			let url = Constants.STOCK_LOCAL_SEMENCE_EXPORT_URL + "?licence_key=" + Constants.USER_LICENCE_KEY
			
			const res = await this.$request.request_get_api("StockSemenceMixin::exportAllStockLocal", url)
				.catch(e => {
					console.error("StockSemenceMixin::exportAllStockLocal => ", e)
					return null
				})
			Common.csvToFile(res.retour, "Export global stock semence.csv")
		},

		loadAllStockByTier(tiers_id) {
			const url = Common.constructRoute(Constants.STOCK_SEMENCE_TIERS_URL, { tiers_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("StockSemenceMixin::loadAllStockByTier", url)
				.catch(e => {
					console.error("StockSemenceMixin::loadAllStockByTier => ", e)
					return null
				})
				.then(res => res.retour)
		},

		async saveInventory(inventories, tiers_id) {
			const params = { inventories }

			const url = Common.constructRoute(Constants.STOCK_EXTERNE_SEMENCE_TIERS_URL, { tiers_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("StockSemenceMixin::saveInventory", url, params, false)
			.catch(e => {
				console.error("StockSemenceMixin::saveInventory", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},

		loadStockByTiersAndHorse(tiers_id, horse_id) {
			const url = Common.constructRoute(Constants.STOCK_SEMENCE_TIERS_HORSE_URL, { tiers_id, horse_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("StockSemenceMixin::loadStockByTiersAndHorse", url)
				.catch(e => {
					console.error("StockSemenceMixin::loadStockByTiersAndHorse => ", e)
					return null
				})
				.then(res => res.retour)
		},

		addStockSemence(params) {
			const url = Constants.STOCK_SEMENCE_URL + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("StockSemenceMixin::addStockSemence", url, {stocks: params}, false)
				.catch(e => {
					console.error("StockSemenceMixin::addStockSemence => ", e)
					return null
				})
				.then(res => res.retour)
		},

		loadAllStockLocalDetailsByStallion(horse_id, light = false) {
			let url = Common.constructRoute(Constants.STOCK_LOCAL_HORSE_URL, { horse_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			if(light) {
				url += "&light=true"
			}

			return this.$request.request_get_api("StockSemenceMixin::loadAllStockLocalDetailsByStallion", url)
				.catch(e => {
					console.error("StockSemenceMixin::loadAllStockLocalDetailsByStallion => ", e)
					return null
				})
				.then(res => res.retour)
		},

		loadAllStockDistantDetailsByStallion(horse_id) {
			const url = Common.constructRoute(Constants.STOCK_DISTANT_HORSE_URL, { horse_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("StockSemenceMixin::loadAllStockDistantDetailsByStallion", url)
				.catch(e => {
					console.error("StockSemenceMixin::loadAllStockDistantDetailsByStallion => ", e)
					return null
				})
				.then(res => res.retour)
		},

		addNewStockStallion(horse_id, params) {
			const url = Common.constructRoute(Constants.STOCK_SEMENCE_HORSE_URL, { horse_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("StockSemenceMixin::addNewStockStallion", url, params, false)
				.catch(e => {
					console.error("StockSemenceMixin::addNewStockStallion => ", e)
					return null
				})
				.then(res => res.retour)
		},

		getAllSemenceCouleur() {
			return this.$storage.db.t('semence_couleur')
			.then(table => {
				return table.toCollection()
			})
			.then(col => {
				return col.toArray()
			})
		},

		useSemence(params) {
			const url = Constants.STOCK_SEMENCE_USE_URL + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("StockSemenceMixin::useSemence", url, {stocks: params}, false)
				.catch(e => {
					console.error("StockSemenceMixin::useSemence => ", e)
					return null
				})
				.then(res => res.retour)
		},

		cancelUseSemence(params) {
			const url = Constants.STOCK_SEMENCE_USE_CANCEL_URL + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("StockSemenceMixin::cancelUseSemence", url, {canceled: params}, false)
				.catch(e => {
					console.error("StockSemenceMixin::cancelUseSemence => ", e)
					return null
				})
				.then(res => res.retour)
		},

		saveRetourStock(params) {
			const url = Constants.STOCK_SEMENCE_RETOUR_URL + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("StockSemenceMixin::saveRetourStock", url, {retour: params}, false)
				.catch(e => {
					console.error("StockSemenceMixin::saveRetourStock => ", e)
					return null
				})
				.then(res => res.retour)
		},

		getFluxStockLocal(begin, end) {
			const url = Common.constructRoute(Constants.STOCK_LOCAL_FLUX_URL, { begin, end }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("StockSemenceMixin::getFluxStockLocal", url)
				.catch(e => {
					console.error("StockSemenceMixin::getFluxStockLocal => ", e)
					return null
				})
				.then(res => res.retour)
		},

		loadStockUsedByActe(acte_id) {
			const url = Common.constructRoute(Constants.STOCK_USED_ACTE_URL, { acte_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("StockSemenceMixin::loadStockUsedByActe", url)
				.catch(e => {
					console.error("StockSemenceMixin::loadStockUsedByActe => ", e)
					return null
				})
				.then(res => res.retour)
		},

		sortieStockSemence(params) {
			const url = Constants.STOCK_SEMENCE_SORTIE_URL + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("StockSemenceMixin::saveRetourStock", url, {stocks: params}, false)
				.catch(e => {
					console.error("StockSemenceMixin::saveRetourStock => ", e)
					return null
				})
				.then(res => res.retour)
		},

		getStockStallionByLocalisation(loc_ids) {
			const url = Constants.STOCK_BY_LOCALISATION + '?licence_key=' + Constants.USER_LICENCE_KEY

			let params = {}
			if(loc_ids) {
				params = {loc_ids}
			}

			return this.$request.request_post_api("StockSemenceMixin::getStockStallionByLocalisation", url, params, false)
				.catch(e => {
					console.error("StockSemenceMixin::getStockStallionByLocalisation => ", e)
					return null
				})
				.then(res => res.retour)
		},

		destructionStock(stocks) {
			const url = Constants.DESTROY_STOCK + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("StockSemenceMixin::destructionStock", url, {stocks: stocks}, false)
				.catch(e => {
					console.error("StockSemenceMixin::destructionStock => ", e)
					return null
				})
				.then(res => res.retour)
		},

		editStock(stock_id, params) {
			const url = Common.constructRoute(Constants.STOCK_SEMENCE_DETAILS_URL, { stock_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("StockSemenceMixin::editStock", url, params, false)
				.catch(e => {
					console.error("StockSemenceMixin::editStock => ", e)
					return null
				})
				.then(res => res.retour)
		},

		editCommentaireStock(stock_id, commentaire) {
			const url = Common.constructRoute(Constants.STOCK_SEMENCE_DETAILS_COMMENTAIRE_URL, { stock_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("StockSemenceMixin::editCommentaireStock", url, {commentaire}, false)
				.catch(e => {
					console.error("StockSemenceMixin::editCommentaireStock => ", e)
					return null
				})
				.then(res => res.retour)
		},

		cancelActeUseSemence(acte_id) {
			const url = Common.constructRoute(Constants.STOCK_USED_ACTE_CANCEL_URL, { acte_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("StockSemenceMixin::cancelActeUseSemence", url)
				.catch(e => {
					console.error("StockSemenceMixin::cancelActeUseSemence => ", e)
					return null
				})
				.then(res => res.retour)
		},

		deleteStock(stock_id) {
			const url = Common.constructRoute(Constants.STOCK_SEMENCE_DETAILS_URL, { stock_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_delete_api("StockSemenceMixin::deleteStock", url)
				.catch(e => {
					console.error("StockSemenceMixin::deleteStock", e)
					return null
				})
				.then(res => res.retour)
		},

		async printCourrierFluxPaillettes(semenceemplacement_ids, model_id, tiers_id) {
			const params = {
				semenceemplacement_ids: semenceemplacement_ids,
				model_id: model_id,
				tiers_id: tiers_id
			}

			const url = this.constructRoute(Constants.PRINT_FLUX_PAILLETTES, {}) + '?licence_key='+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("StockSemenceMixin::printCourrierFluxPaillettes", url, params, false)
			.catch(error => {
                console.error("StockSemenceMixin::printCourrierFluxPaillettes => ERROR", error)
                return null
            })

			if(result) {
				Common.base64ToPdf(result, "flux_" + tiers_id + ".pdf")
			}
			else {
				return result
			}
		},

		async createOrUpdateLocSemenceFloors(parent_id, nb_floors) {
			for(let i = 0; i < nb_floors; i++) {
				let floor = await this.$storage.db.t('semence_localisation')
				.then(table => {
					return table.where({
						semencelocalisation_parent: parent_id,
						semencelocalisation_order: i+1 
					}).first()
				})

				if(!floor) {
					await this.$storage.db.t('semence_localisation')
	                .then(table => {
	                    return table.add({
	                    	semencelocalisation_id: Common.getNegativeId(),
	                        semencelocalisation_parent: parent_id,
	                        semencelocalisation_libelle: i+1,
	                        semencelocalisation_licencekey: Constants.USER_LICENCE_KEY,
	                        semencelocalisation_valide: 1,
	                        semencelocalisation_order: i+1,
	                        semencelocalisation_is_floor: true
	                    })
	                })
				}
			}
		}
	}
}
