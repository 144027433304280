<template>
	<div>
		<div class="row">
			<div class="col-10">
				<h2>{{ $t('monte.congelation.creation_lot') }}</h2>
			</div>
			<div class="col-2">
				<div class="text-right">
					<b-button @click="addLot">
						<font-awesome-icon :icon="['fal', 'plus']" />
						{{ $t('global.ajouter') }}
					</b-button>
				</div>
			</div>
		</div>

		<div v-for="(lot, index) in lots" :key="index" class="row border py-2">
			<div class="col-12 text-right">
				<button @click="removeLot(lot)" class="btn btn-secondary"><font-awesome-icon :icon="['fal', 'trash-alt']" /></button>
			</div>

			<div class="col-6">
				<label>{{ $t('monte.congelation.volume') }}</label>
				<b-input type="text" class="form-control" v-model="lot.semencelot_volume"/>
			</div>

			<div class="col-6">
				<label>{{ $t('monte.congelation.nb_paillettes') }}</label>
				<b-input type="text" class="form-control" v-model="lot.semencelot_qte"/>
			</div>

			<div class="col-6">
                <label for="couleur" class="col-form-label">{{ $t("monte.congelation.couleur") }} *</label>
                <e-select
                    v-model="lot.semencelot_couleur"
                    id="couleur"
                    track-by="semencecouleur_code"
                    :placeholder="$t('monte.stock_semence.selectionnez_couleur')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="all_couleurs"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                >
                	<template slot="option" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
	               	<template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
	               	<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
            </div>

			<div class="col-6">
                <label for="dilueur" class="col-form-label">{{ $t("monte.congelation.dilueur") }} *</label>
                <e-select
                    v-model="lot.semencelot_dilueur"
                    id="dilueur"
                    track-by="semencedilueur_id"
                    :placeholder="$t('monte.congelation.selectionnez_dilueur')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="all_dilueurs"
                    label="semencedilueur_label"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                />
            </div>

			<div class="col-6">
				<label>{{ $t('monte.congelation.observation') }}</label>
				<b-input type="text" class="form-control" v-model="lot.semencelot_observation"/>
			</div>

			<div class="col-6">
				<label>{{ $t('monte.congelation.comportement') }}</label>
				<b-input type="text" class="form-control" v-model="lot.semencelot_comportement"/>
			</div>

			<div class="col-12">
				<label>{{ $t('monte.congelation.commentaire') }}</label>
				<b-input type="text" class="form-control" v-model="lot.semencelot_commentaire"/>
			</div>
		</div>

		<div class="row mt-4">
			<div class="col-10">
				<h2>{{ $t('monte.congelation.creation_dose') }}</h2>
			</div>
			<div class="col-2">
				<div class="text-right">
					<b-button v-if="!dose.show" @click="addDose">
						<font-awesome-icon :icon="['fal', 'plus']" />
						{{ $t('global.ajouter') }}
					</b-button>
					<b-button v-else @click="removeDose">
						<font-awesome-icon :icon="['fal', 'times']" />
						{{ $t('global.supprimer') }}
					</b-button>
				</div>
			</div>
		</div>
		<div v-if="dose.show" class="row">
			<div class="col-6">
				<label>{{ $t('monte.congelation.volume') }}</label>
				<b-input type="text" class="form-control" v-model="dose.semencedose_volume"/>
			</div>

			<div class="col-6">
				<label>{{ $t('monte.congelation.nb_dose') }}</label>
				<b-input type="text" class="form-control" v-model="dose.semencedose_qte"/>
			</div>
		</div>

		<div class="row mt-4">
			<div class="col-12 text-center">
				<button class="btn btn-primary" @click="checkForm" :disabled="!dose.show && lots.length == 0">
					<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
					<font-awesome-icon v-else :icon="['far', 'save']" />
					{{ $t('global.sauvegarder') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Actes from "@/mixins/Actes.js"
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
	import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: 'AddSemenceLot',
		mixins: [Actes, Congelation, StockSemence, Shutter, ShutterTriggers],
        props: {
            horses_ids: { type: Array, default: () => [] },
            date_acte: { type: Date, default: () => new Date() },
			actes_ids: { type: Array, default: () => [] },
            shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] }
        },
		data () {
			return {
				lots: [],
				default_lot: {
					semencelot_date: null,
					semencelot_volume: '',
					semencelot_qte: 1,
					semencelot_comportement: '',
					semencelot_observation: '',
					semencelot_commentaire: '',
					semencelot_horse: null,
					semencelot_dilueur: null,
					semencelot_couleur: null
				},
				all_couleurs: [],
				all_dilueurs: [],
				dose: {
					show: false,
					semencedose_qte: 1,
					semencedose_volume: '',
					semencedose_date: null,
					semencedose_horse: null,
					semencedose_acte: null
				},
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.all_couleurs = await this.getAllSemenceCouleur()
				this.all_dilueurs = await this.getAllDilueurs()
				const stallion_settings = await this.getStallionSettings(this.horses_ids[0])

				if(stallion_settings) {
					this.default_lot.semencelot_couleur = this.all_couleurs.find(c => c.semencecouleur_id == stallion_settings.semencestallion_couleur)
				}

				const acte = await this.getActeWithReponse(this.actes_ids[0])

				const volume = acte.reponse.find(rep => rep.reponse_question_code == 'VOL')
				const nb_paillette = acte.reponse.find(rep => rep.reponse_question_code == 'NB_PAIL')

				this.default_lot.semencelot_volume = volume ? volume.reponse_data[0] : ''
				this.default_lot.semencelot_qte = nb_paillette ? nb_paillette.reponse_data[0] : ''

				this.dose.semencedose_volume = volume ? volume.reponse_data[0] : ''
				this.dose.semencedose_date = this.date_acte
				this.dose.semencedose_horse = this.horses_ids[0]
				this.dose.semencedose_acte = this.actes_ids[0]
			},

			addLot() {
				this.lots.push(_cloneDeep(this.default_lot))
			},

			removeLot(lot) {
				this.lots.splice(this.lots.findIndex(l => l == lot), 1)
			},

			addDose() {
				this.dose.show = true
			},

			removeDose() {
				this.dose.show = false
			},

			async checkForm() {
				this.processing = true

				const lots = this.lots.map(lot => {
					return {
						semencelot_date: this.date_acte,
						semencelot_horse: this.horses_ids[0],
						semencelot_acte: this.actes_ids[0],
						semencelot_volume: lot.semencelot_volume,
						semencelot_qte: lot.semencelot_qte,
						semencelot_comportement: lot.semencelot_comportement,
						semencelot_observation: lot.semencelot_observation,
						semencelot_commentaire: lot.semencelot_commentaire,
						semencelot_dilueur: lot.semencelot_dilueur ? lot.semencelot_dilueur.semencedilueur_id : null,
						semencelot_couleur: lot.semencelot_couleur ? lot.semencelot_couleur.semencecouleur_id : null
					}
				})

				if(lots.length > 0)
					await this.createSemenceLots(lots)

				if(this.dose.show)
					await this.createSemenceDose(this.dose)

				this.processing = false

				if(this.next.length > 0) {
                    const triggered = this.next[0]
                    this.next.shift()
                    this.shutterPanel().close('add_semence_lot')
                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.next)
                } else { 
                    let horse_id = this.$sync.replaceWithReplicated('horse', this.horses_ids[0])
                    this.$router.push({ name: 'HorseFichePlanning', params: {horse_id} })
                }
			}
		},

		components: {
			
		}
	}

</script>